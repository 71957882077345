@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Bowlby+One+SC&family=Fira+Code:wght@300..700&display=swap');



.MainPage {
    min-height: 100vh;
    font-family: "Fira Code", monospace;
    color: lightgray;
    background-color: #111c26;
    border-radius: 15px;
  }


.Turquise {
  color: rgb(67 217 173);
}

.Welcome {
  background-color: #111c26;
  
}

.App-header {
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
  gap: 10%;
}


.header-text {
  text-align: right;
}

.header-photo {
  background-color: #274755;
  border-radius: 50%; 
  box-shadow: 0px 0px 15px rgb(67 217 173);
  padding: 20px;
  margin-right: 1rem;
  border: solid rgb(67 217 173) 3px;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  transition: transform 0.3s ease;
}

.header-photo:hover .App-logo{
  transform: scale(1.1);
}

.Project-card, .Small-project-card, .Experience-card, .About-me-card, .header-photo{
  z-index: 10;
}



.Projects{
  background-color: #111c26;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}


.Projects h1{
  text-align: center;
  margin: 20px 0;
}


.Project-card {
  background-color: #0f1821;
  transition: transform 0.3s ease;
  margin-top: 3rem;
  border-radius: 10px;
  padding: 1rem;
  border: solid rgb(71, 71, 71) 1px;
  max-width: 1700px;
}



.Project-card:hover, .Small-project-card:hover {
  transform: translateY(-3px);
  border: solid #305869 1px;
  background-color: #274755;
  box-shadow: 0px 3px 3px rgb(67 217 173);
}

.Project-card:hover img{
  transform: translateY(-2px);
}

.Project-card:hover button{
  background-color: rgb(23, 23, 23);
}


.Desc {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-direction: row-reverse;
  overflow: hidden;
  gap: 2rem;
}


.Card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card-actions {
  display: flex;
  justify-content: flex-end;
}

.Project-photo {
  object-fit: cover;
  object-position: center;
  width: 50vmin;
  height: 95%;
  margin-top: 10px;
  border-radius: 10px;
}

.Card-actions button {
  width: auto;
  height: 2.5rem;
  background-color: rgb(51, 63, 73);
  color: lightgray;
  margin-right: 1rem;
  border-radius: 5px;
  border: none;
  padding: 10px;
}


@media screen and (max-width: 1200px) {
  .Desc {
    flex-wrap: wrap;
    justify-content: center;
  }
  .Project-photo {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%
  }

  .Project-card {
    margin: 1rem;
  }
}


.Small-project-cards-container {
  display: flex;
  flex-wrap: wrap;
  
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  max-width: 1700px;
  gap: 1.5rem;
  
}

.Projects h2 {
  margin: 3rem 0 0 0 
}

.Small-project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20rem;
  max-width: 20rem;
  border: solid black 1px;
  padding: 10px;
  border: solid rgb(71, 71, 71) 1px;
  border-radius: 10px;
  background-color: #0f1821;
  transition: transform 0.3s ease;
}


.Small-project-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem
}

.Small-project-actions .Link{
  text-align: center;
  width: 50%;
  background-color: rgb(51, 63, 73);
  border-radius: 5px;
  padding: 10px;
}

.Small-project-actions .Action-button {
  background-color: rgb(51, 63, 73);
  border: none;
}

.Small-project-card:hover .Link, .Small-project-card:hover .Action-button{
  background-color: rgb(23, 23, 23);
}


.Justify-text {
  text-align: justify;
}

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 24))}
}


.slider {
  background: transparent;
  height: 15rem;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 60%;
  margin: 5rem 0 0 0
}

.slider::before,
.slider::after {
  content: "";
  position: absolute;
  z-index: 2;
  width: 10%; /* Šířka gradientu na okrajích slideru */
  height: 100%;
  pointer-events: none;
}

.slider::before {
  left: 0;
  top: 0;
  background: linear-gradient(to right, #111c26 0%, rgba(255, 255, 255, 0) 100%);
}

.slider::after {
  right: 0;
  top: 0;
  background: linear-gradient(to left, #111c26 0%, rgba(255, 255, 255, 0) 100%);
  transform: rotateZ(0deg); /* Oprava rotace */
}

.slide-track {
  display: flex;
  animation: scroll 90s linear infinite;
  width: calc(250px * 200); /* Šířka tracku podle počtu slideů */
}

.slide {
  height: 100px;
  width: 250px;
}

.Experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.Experience-Info-Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1700px;
  margin-top: 5rem
}

.Experience-card {
  width: 20rem;
  height: 15rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: transform 0.3s ease;
  background-color: #0f1821;
  padding: 5px;
  border-radius: 10px;
  border: solid rgb(71, 71, 71) 1px;
}

.Experience-card:hover {
  border: solid rgb(67 217 173) 3px;
  transform: translateY(-12px);
  box-shadow: 0px 0px 15px rgb(67 217 178);
}





.Bigger-card {
  width: 21rem;
  height: 18rem;
}

.Experience h1 {
  margin-top: 3rem
}


.No-points {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  padding-bottom: 15px;
}

.No-points li {
  padding-bottom: 3px;
}


.Express {
  filter: invert(100%)
}

.About-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0 20px;
  padding: 5rem 0;

}

.About-me-card {
  max-width: 1200px;
  border: solid rgb(71, 71, 71) 1px;
  border-radius: 10px;
  margin-top: 15px;
  background-color: #0f1821;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 25px;
  letter-spacing: 1.5px;
  margin-top: 5rem
}

@media screen and (max-width: 800px) {
  .Portfolio {
    margin: 5px;
  }
  .App-header {
    flex-direction: column;
  }
  .header-text {
    text-align: center;
  }
  .Projects {
    padding: 0
  }
  .Project-card {
    margin: 1rem
  }
  .slide-track {
    animation: scroll 60s linear infinite;
  }
}

@media screen and (max-width: 1200px) {
  .Desc {
    flex-wrap: wrap;
    justify-content: center;
  }
  .Project-photo {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%
  }

  .Project-card {
    margin: 1rem;
  }

}

@media screen and (max-width: 1320px) {
  .Bigger-card {
    width: 18rem;
    height: 15rem;
  }
  .Experience-card {
    width: 18rem;
    height: 15rem;
    margin: 1rem
  }
}