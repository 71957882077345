@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

.ShutTheBox {
    font-family: Verdana, Geneva, Tahoma, sans-serif, 'Segoe UI', 'Apple Color Emoji';
    background-color: #121212;
    min-height: 100vh;
    margin: 0;
}

.ShutTheBox h1 {
  color: lightgray;
}


.ShutTheBox .Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.ShutTheBox .NumberButton {
  height: 5rem;
  width: 5rem;
  margin: 3px;
  font-size: 40px;
}

.ShutTheBox .btn-success {
  --bs-btn-disabled-bg: #198754 !important;
  --bs-btn-disabled-opacity: 1;
}

.ShutTheBox .NumberButtons {
  text-align: center;
  margin-bottom: 50px;
}

.ShutTheBox .DiceButton {
  height: 10rem;
  width: 10rem;
  margin-left: 5px;
  background-color: #212529;
  border-color: #212529;
  color: lightgray
}

.ShutTheBox .DiceButtons {
  margin: 50px;
}

.ShutTheBox .RollButton {
  height: 5rem;
  width: 20%;
}

.ShutTheBox h3 {
    color: black
}

.ShutTheBox h1 {
  text-align: center;
  font-family: "Bowlby One SC", sans-serif;
  font-weight: 400;
  font-size: 8rem
}

.ShutTheBox h2 {
  font-size: 8rem;
}

.ShutTheBox .Modal, .modal-header {
  text-align: center;
  display: block;
}

.Modal .modal-content {
  color: lightGray;
  text-align: center
}


@media screen and (max-width: 1200px) {
  .ShutTheBox  h1 {
    font-size: 5rem; 
  }
  .ShutTheBox .DiceButtons {
    margin: 10px;
  }
  .ShutTheBox .NumberButtons {
    text-align: center;
    margin-bottom: 10px;
  }
  .ShutTheBox .DiceButton {
    height: 6rem;
    width: 6rem;
  }
  .ShutTheBox h2 {
    font-size: 4rem;
  }
  .ShutTheBox .RollButton {
    height: 5rem;
    width: 12rem;
  }
}

@media screen and (max-width: 600px) {
    .ShutTheBox h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 400px) {
    .ShutTheBox h1 {
    font-size: 2rem; 
  }
}