@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');

.GuessTheNumber {
  color: lightGray
}

.GuessTheNumber {
  text-align: center;
  background-color: #121212;
  min-height: 100vh;
  margin: 0;
}

.GuessTheNumber h1 {
  text-align: center;
  font-family: "Bowlby One SC", sans-serif;
  font-weight: 400;
  font-size: 8rem
}

.GuessTheNumber .GuessInput {
  display: flex;
  justify-content: center;
  margin-top: 5rem;

}

.GuessTheNumber .GuessInputForm {
  width: 300px;
  height: 200px;
  font-size: 100px;
  border-radius: 10px 0 0 10px !important;
  text-align: center;
  background-color: #212529;
  border: none;
  color: lightslategray
}

.GuessTheNumber .GuessInputButton {
  height: 200px;
  width: 6rem;
  font-size: 40px;
  border-radius: 0 10px 10px 0 !important

}

.Modal .modal-content {
  color: lightGray;
  text-align: center
}

.GuessTheNumber h3 {
  margin-top: 40px
}

@media screen and (max-width: 1200px) {
    .GuessTheNumber h1 {
    font-size: 5rem
  }
}

@media screen and (max-width: 600px) {
  .GuessTheNumber h1 {
    font-size: 3rem
  }
  .GuessTheNumber .GuessInputForm {
    width: 200px;
    height: 150px;
    font-size: 60px;
  }
  .GuessTheNumber .GuessInputButton {
    height: 150px;
    width: 6rem;
    font-size: 40px;
    border-radius: 0 10px 10px 0 !important
  
  }
}

@media screen and (max-width: 400px) {
    .GuessTheNumber h1 {
    font-size: 2rem
  }
}

