.siteName {
    position: absolute;
    top: 40px;
    color: white;
    font-size: 65px;
    color: orange;
  }

.hexaInput {
    background-color: #6c757d;
    border: none;
    text-align: center;
    border-radius: 10px;
    width: 51%;
}

.hexaButton {
    margin: 0;
    padding: 15px;
    width: 51%;
    background-color: #e65319;
    color: white;
    font-size: 20px;
    border-radius: 10px;
}


.result {
    font-size: 80px;
}

.HexaConverter{
    text-align: center;
    background-color: #121212;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    color: white;
  }