.floating-button {
    position: fixed;
    top: 30px;
    left: 30px;
    width: 80px;
    height: 80px;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000; 
    background-color: #212529;
  }

.floating-button:hover {
  background-color: #212529;
}

@media screen and (max-width: 600px) {
  .floating-button {
    top: 50px;
    left: 10px;
    width: 50px;
    height: 50px;
  }
}